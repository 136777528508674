import * as React from 'react';
// importを追記
import { useStaticQuery, graphql, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Moment from 'react-moment';
// componentsを追記
import Layout from '../../components/layout';
import SEO from '../../components/seo';

const PageEN = () => (
<Layout>
  {/* meta要素 */}
  {/* (NULLの場合はgatsby-config.jsのsiteMetadataを使用する) */}
  <SEO
    title="Static page sample | TUREDUREGUSA, Modern translation"
    description=""
    keyword=""
    site_name=""
    url=""
    og_title=""
    og_type=""
    og_image=""
    tw_cardType=""
    tw_userName=""
  />
  {/* html要素 */}
  <div className="g-container">
    <main className="g-pageMain" role="main">
      <div className="l-container">
        <div className="u-mt-x5">
          <h1 className="c-headingLv1">Static page sample</h1>
          <p className="c-text">This is a sample static page.</p>
          <p className="c-text"><Link to={`/page/`}>Japanese translation</Link></p>
          <p className="c-text"><Link to="/en/">Back to home</Link></p>
        </div>
        <div className="u-mt-x5 u-ta-right">
          <p className="c-pageTop" data-js-localscroll>
            <a href="#top">
              <img
                className="i-label i-label-left"
                src="/assets/img/icons/ic_tri_up.svg"
                alt=""
              />
              Page top
            </a>
          </p>
        </div>
      </div>
      {/* /.l-container */}
    </main>
    {/* /.g-pageMain */}
  </div>
  {/* /.g-container */}
</Layout>
);

export default PageEN;
